/* @keyframes animateGradientBorder {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.border-gradient {
  border: 3px solid transparent;
  background-image: linear-gradient(to right, #f56565, #fb923c, #805ad5);
  background-size: 200% auto;
  animation: animateGradientBorder 5s linear infinite;
} */
.custom-dropdown .ant-select-single {
  height: 3rem !important;

  border-radius: 0% !important;
}
.custom-dropdown .ant-select-selector {
  border-radius: 0% !important;
  background-color: #dc2626 !important;
  border: 1px solid #dc2626 !important;
}
.custom-dropdown .ant-select-selection-item {
  color: white !important;
  font-size: 16px;
}
.custom-dropdown .ant-select .ant-select-arrow .anticon {
  color: white !important;
}

.left {
  text-align: left;
}

.mt-16 {
  margin-top: 64px
}

a {
  text-decoration: underline;
  font-weight: normal;
}

.numerical_list {
  all: initial;
}

{
  font-weight: 100;
}

iframe {
  margin: 0 auto;
  margin-top: 16px;
  width: 90%;
  max-width: 768px;
  aspect-ratio: 16/9;
}

h2 b {
  font-size: 1.5rem;
}

.logo_container {
  width: 200px;
  height: 100px;
  margin: auto 0;
}

.logo {
  width: 200px;
}

.illustration {
  margin: 0 auto;
  margin-top: 16px;
  width: 60%;
  aspect-ratio: initial;
}

.download_button {
  padding: 15px 20px;
  background: red;
  border-radius: 20px;
  text-decoration: none;
  color: white;
}